<script lang="ts" setup>
import type { InputHTMLAttributes } from 'vue';

interface Props extends /* @vue-ignore */ InputHTMLAttributes {}

defineProps<Props>();
const value = defineModel<boolean>({ default: () => false });
</script>

<template>
  <SwitchRoot v-model:checked="value" class="baseSwitch">
    <span class="baseSwitch__block" />
    <SwitchThumb class="baseSwitch__pin" />
  </SwitchRoot>
</template>

<style lang="scss">
.baseSwitch {
  position: relative;
  width: 44px;
  flex: 0 0 44px;
  height: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #e0e0e0;
  border-radius: 23px;
  border: none;
  padding: 0;
  cursor: pointer;

  @include mq('sm') {
    width: 37px;
    flex: 0 0 37px;
    height: 20px;
  }

  &[data-state='checked'] &__block {
    transform: translateX(0);
  }

  &:active &__pin {
    width: 25px;

    @include mq('sm') {
      width: 20px;
    }
  }

  &[data-state='checked'] &__pin {
    transform: translateX(20px);

    @include mq('sm') {
      transform: translateX(17px);
    }
  }

  &[data-state='checked']:active &__pin {
    transform: translateX(15px);

    @include mq('sm') {
      transform: translateX(13px);
    }
  }

  &__block {
    position: absolute;
    width: 100%;
    aspect-ratio: 1 / 1;
    display: block;
    background-color: #121212;
    border-radius: 50%;
    transition: transform 0.2s ease;
    transform: translateX(-100%);
  }

  &__pin {
    position: absolute;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #fff;
    display: block;
    transition:
      transform 0.2s ease-in-out,
      width 0.2s ease;

    @include mq('sm') {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
